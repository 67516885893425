<template lang="pug">
aside.contacts-line
  .contacts-line__head.cont(v-if="title")
    h2.contacts-line__title.title {{ title }}
  .contacts-line__wrapper
    .contacts-line__cont.cont
      .contacts-line__area
        .contacts-line__info
          span.contacts-line__address
            span.ff-garamond {{city}},
            br
            span.ff-garamond {{addressShort}}
          ul.contacts-line__worktime
            li.contacts-line__worktime-item.text(v-if="worktime.weekdays" v-html="worktime.weekdays")
            //li.contacts-line__worktime-item.text(v-if="worktime.weekends") {{worktime.weekends}}
          .contacts-line__contacts
            a.contacts-line__link.contacts-line__link_phone.text-big(:href="phone.general.link") {{phone.general.mask}}
            span.contacts-line__note.text Для интернет-заказов
            a.contacts-line__link.contacts-line__link_phone.text-big(:href="phone.onlineOrder.link") {{phone.onlineOrder.mask}}
            a.contacts-line__link.contacts-line__link_mail.text-big(:href="mail.link") {{mail.mask}}
          .contacts-line__social
            a.contacts-line__social-link(v-for="(social, i) in socials", :key="i", :href="social.link", target="_blank", rel="noopener nofollow noreferrer")
              img.contacts-line__social-image(:src="`/images/ic_${social.mod}_dark.svg`", :alt="`${social.name} иконка`", :title="social.name", loading="lazy", width="30", height="30")
        .contacts-line__map-box
          yandex-map.contacts-line__map(
            :coords="[60.71623856, 28.72369000]",
            :zoom="17",
            :controls="['zoomControl']",
            :behaviors="['drag', 'multiTouch', 'dblClickZoom']"
          )
            ymap-marker(:coords="[60.71623856, 28.72369000]", markerId="1")
</template>

<script>
import '@/assets/styles/components/contacts-line.sass'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import { socials } from '../../assets/scripts/socials'
import { phone, mail, worktime, addressShort, city } from '../../assets/scripts/variables'

export default {
  name: 'ContactsLine',
  components: { yandexMap, ymapMarker },
  data () {
    return {
      socials,
      phone,
      mail,
      worktime,
      addressShort,
      city,
      title: ''
    }
  }
  // watch: {
  //   '$route.name' () {
  //     if (this.$route.name === 'Product') {
  //       this.title = 'Купите в Музее «Шоколадный Выборг»'
  //     } else {
  //       this.title = ''
  //     }
  //     if (this.$route.name === 'Contacts' || this.$route.name === 'Franchise') {
  //       this.isShowMap = false
  //     } else {
  //       this.isShowMap = true
  //     }
  //   }
  // }
}
</script>
