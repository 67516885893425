<template lang="pug">
aside.own-production
  .own-production__cont.cont
    h2.own-production__title.title Отлаженные бизнес-процессы
    ul.own-production__list
      li.own-production__item(v-for="(item, i) in list", :key="i")
        span.own-production__headline.headline {{ item.headline }}
        p.own-production__text.text {{ item.text }}
</template>

<script>
import '@/assets/styles/components/own-production.sass'

export default {
  name: 'OwnProduction',
  data () {
    return {
      list: [
        {
          headline: 'Мы обсудим все условия нашего с вами сотрудничества',
          text: 'Мы предоставим вам всю необходимую информацию, чтобы принять взвешенное решение по открытию бизнеса и вместе посетим действующий Музей Шоколада в г.Выборг'
        }, {
          headline: 'Подписание договора и оплата',
          text: 'Подписываем договор коммерческой концессии, который будет защищать ваши права'
        }, {
          headline: 'Первые шаги к открытию вашего Музея Шоколада',
          text: 'Мы обеспечиваем вас методикой подбора правильного места и помогаем на всех этапах подбора и оценки помещения'
        }, {
          headline: 'Анализ и финансовый подсчет предлагаемого места',
          text: 'Составляем финансовую модель на основании всех показателей. Выбираем наилучшее место'
        }, {
          headline: 'Создаем проект музея',
          text: 'Разрабатываем дизайн проект и визуализируем на основе анкеты согласования и фотографий. Создаем проект развертки оборудования'
        }, {
          headline: 'Подписание договора аренды с ТЦ/БЦ',
          text: 'Утверждаем проект со стороны ТЦ/БЦ и анализируем договор аренды'
        }
      ]
    }
  }
}
</script>
