<template lang='pug'>
article.postcards
  .postcards__cont.cont
    .postcards__area
      h2.postcards__title.title Открытки Выборга
      p.postcards__text.text
        | За чашечкой горячего шоколада в нашем музее вы можете подписать авторскую почтовую открытку с лучшими видами
        | Выборга, а наша команда проследит, чтобы её доставили по нужному адресу
      p.postcards__contact-text.text
        | Свяжитесь с нами по телефону
      a.postcards__contact-link.title(:href="phone.onlineOrder.link") {{phone.onlineOrder.mask}}
      picture.postcards__picture
        source(srcset="/images/postcards/postcards-1_341.webp 1x, /images/postcards/postcards-1_682.webp 2x", type="image/webp", media="(max-width: 520px)")
        source(srcset="/images/postcards/postcards-1_468.webp 1x, /images/postcards/postcards-1_936.webp 2x", type="image/webp", media="(max-width: 700px)")
        source(srcset="/images/postcards/postcards-1_440.webp 1x, /images/postcards/postcards-1_880.webp 2x", type="image/webp", media="(max-width: 950px)")
        source(srcset="/images/postcards/postcards-1_380.webp 1x, /images/postcards/postcards-1_760.webp 2x", type="image/webp", media="(max-width: 1240px)")
        source(srcset="/images/postcards/postcards-1_565.webp 1x, /images/postcards/postcards-1_848.webp 1.5x", type="image/webp")
        source(srcset="/images/postcards/postcards-1_565.png 1x, /images/postcards/postcards-1_848.png 1.5x")
        img.postcards__image(src="/images/postcards/postcards-1_565.png", loading="lazy", alt="Почтовые открытки Выборга", title="Почтовые открытки Выборга", width="565", height="465")
</template>

<script>
import '@/assets/styles/components/postcards.sass'
import { phone } from '../../assets/scripts/variables'

export default {
  name: 'ThePostcards',
  data () {
    return {
      phone
    }
  }
}
</script>
